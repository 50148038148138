<template>
	<div :class="themeClass" class="loginPassCont width-fill margin-t-10 flex-column bg-fff">
		<el-row>
			<el-col class="font-size18 font-weight700 margin-b-10 titleText">登录密码</el-col>
		</el-row>
		<div class="contentBox width-fill bg-fff" v-model="loginObj">
			<div class="topCont margin-auto">
				<el-steps :active="active" :align-center="true">
					<el-step title="身份认证"></el-step>
					<el-step title="设置操作"></el-step>
					<el-step title="完成"></el-step>
				</el-steps>
			</div>
			<!-- 6228481269040908170 -->
			<!-- 身份认证 -->
			<div class="botCont margin-auto text-center" v-if="this.active == 1">
				<span class="iconfont margin-b-40 imgBox color-theme">&#xe603;</span>
				<p class="font-color-333 margin-b-40">{{loginObj.pText}}</p>
				<div class="btnText font-size18 font-color-333 border-E4E4E4 pointer" @click="cardBtn"><i>使用</i>{{loginObj.buttonText1}}</div>
				<div class="btnText font-size18 font-color-333 border-E4E4E4 pointer" @click="phoneBtn"><i>使用</i>{{loginObj.buttonText2}}</div>
			</div>
		</div>
		<!-- 银行卡添加弹框 start-->
		<el-dialog :visible.sync="cardVisible" width="425px">
			<div class="text-center margin-b-25">
				<span class="iconfont icon-iconset0291 radius30"></span>
				<p class="font-size18 font-weight700 font-color-999">已绑定银行卡</p>
			</div>
			<el-form :model="cardForm" :rules="rules" ref="cardForm">
				<el-form-item label="" prop="checkcard" :label-width="formLabelWidth">
					<el-select class="width-fill" v-model="cardForm.checkcard" placeholder="请选择银行卡">
						<el-option v-for="item in checkcardList" :key="item.id" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="" prop="cardNum" :label-width="formLabelWidth">
					<el-input v-model="cardForm.cardNum" autocomplete="off" placeholder="请输入银行卡号"></el-input>
				</el-form-item>
				<el-form-item label="" prop="idNum" :label-width="formLabelWidth">
					<el-input v-model="cardForm.idNum" autocomplete="off" placeholder="请输入身份证号"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<div class="buttonNext bg-theme font-color-fff text-center pointer" @click="submitCardInform('cardForm')">下一步</div>
			</div>
		</el-dialog>
		<!-- 银行卡添加弹框 end-->
		<!-- 手机添加弹框 start-->
		<el-dialog :visible.sync="phoneVisible" width="425px">
			<div class="text-center margin-b-25">
				<span class="iconfont icon-iconset0291 radius30"></span>
				<p class="font-size18 font-weight700 font-color-999">使用 手机短信验证码</p>
			</div>
			<el-form :model="phoneForm" :rules="rulePhones" ref="phoneForm">
				<el-form-item label="" prop="telephoneNum" :label-width="formLabelWidth">
					<div>当前手机号{{phoneForm.telephoneNum | formatPhone}}</div>
				</el-form-item>
				<el-form-item label="" prop="teleCode" :label-width="formLabelWidth">
					<div class="codeBox flex-row">
						<el-input class="codeInput" v-model="phoneForm.teleCode" autocomplete="off" placeholder="请输入手机验证码"></el-input>
						<div class="codeBtn font-size13 text-center"><span class="iconfont margin-r-10 font-size20">&#xe634;</span>获取短信验证码</div>
					</div>

				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<div class="buttonNext bg-theme font-color-fff text-center pointer" @click="submitPhoneInform('phoneForm')">提交验证</div>
			</div>
		</el-dialog>
		<!-- 手机卡添加弹框 end-->
	</div>
</template>

<script>
	import {
		mapGetters
	} from "vuex";
	export default {
		data() {
			return {
				active: 1,
				cardVisible: false, //银行卡弹框
				phoneVisible: false, //手机信息弹框
				pwdType: "password",
				eyeType: "eye",
				passShow: false,
				loginObj: {
					pText: "为确认是您本人操作，请选择以下任一方式完成身份认证",
					buttonText1: "关联银行卡信息",
					buttonText2: "手机短信验证码"
				},
				cardForm: {
					checkcard: "",
					cardNum: "6228481269040908170",
					idNum: "610125199505175689"
				},
				phoneForm: {
					telephoneNum: "15165849785",
					teleCode: 655111,
				},
				checkcardList: [{
						id: 1,
						name: "中国银行"
					},
					{
						id: 2,
						name: "上海银行"
					}
				],
				formLabelWidth: '0px',
				rules: {
					checkcard: [{
						required: true,
						message: '请选择银行卡信息',
						trigger: 'blur'
					}],
					idNum: [{
							required: true,
							message: '请输入身份证号',
							trigger: 'blur'
						},
						{
							min: 15,
							max: 18,
							message: '请填写18位号码',
							trigger: 'blur'
						},
						{
							required: true,
							pattern: /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/,
							message: '请输入正确的身份证号码',
							trigger: 'blur'
						}
					],
					cardNum: [{
						required: true,
						message: '银行卡不能为空',
						trigger: 'blur'
					}, {
						min: 13,
						max: 19,
						message: '银行卡号13~19位',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^([1-9]{1})(\d{12}|\d{18})$/,
						message: '请输入正确的银行卡号',
						trigger: 'blur'
					}]

				},
				rulePhones: {
					teleCode: [{
						required: true,
						message: '验证码不正确',
						trigger: 'blur'
					}]
				},
				
			};
		},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			//使用银联卡信息
			cardBtn() {
				this.cardVisible = true;
			},
			//银联卡绑定验证
			submitCardInform(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.active = 2;
						this.cardVisible = false;

						this.$router.push({
							path: '/PersonalContent/LoginSetPass',
							query: '',
						});

					} else {
						return false;
					}
				});
			},
			//手机绑定验证
			submitPhoneInform(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.active = 2;
						this.phoneVisible = false;

					} else {
						return false;
					}
				});
			},
			//使用手机短信验证
			phoneBtn() {
				this.phoneVisible = true;

			}
		},
		filters: {
			//格式化手机号加密
			formatPhone: function(val) {
				console.log(val);
				return `${val.substring(0,3)}****${val.substring(val.length-3)}`
			}

		}
	};
</script>

<style scoped lang="scss">
	.bg-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.loginPassCont {
		height: calc(100% - 10px);

		.titleText {
			padding: 8px 14px;
		}
	}

	i,
	span {
		display: inline-block;
	}

	.contentBox {
		height: 100%;

		.topCont {
			width: 500px;
		}

		.botCont {
			width: 400px;
		}

		.topCont {
			margin-bottom: 40px;
			display: flex;
			flex-direction: row;
			justify-content: center;

			.fontIcon {
				width: 32px;
				height: 32px;
				line-height: 32px;
				border: 4px solid #FAFAFA;
				background-color: #E4E4E4;
			}

		}

		.imgBox {
			font-size: 58px;
		}

		.btnText {
			width: 416px;
			height: 56px;
			line-height: 56px;
			margin: 20px auto;
			cursor: default;
		}

		i {
			font-style: normal;
			margin-right: 10px;

		}
	}

	.icon-iconset0291 {
		border-radius: 30px;
		font-size: 50px;
		color: #999;
	}

	::v-deep .el-input__inner {
		height: 35px;
		line-height: 35px;
	}

	.buttonNext {
		height: 45px;
		line-height: 45px;
	}

	.combackBtn {
		/* width: 380px; */
		margin-top: 60px;
	}

	.icon-select_full {
		color: #00CC88;
		font-size: 42px;
		vertical-align: middle;
		margin-right: 10px;
	}

	.codeBox {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.codeInput,
		.codeBtn {
			width: 48%;
		}

		.codeBtn {
			height: 28px;
			line-height: 28px;
			border: 1px solid #eee;

			span {
				vertical-align: middle;
				margin-top: -3px;
			}
		}
	}

	.el-form-item {
		margin-bottom: 15px;
	}
	::v-deep .el-input__suffix{
		right: 0px;
	}
	::v-deep .el-dialog {
		height: 510px;
	}

	/* 步骤条 */
	::v-deep .el-steps--horizontal {
		width: 100%;
	}

	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
		border-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid
	}

	::v-deep .is-finish .el-step__icon.is-text {
		border: 5px solid #DFFFF5;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 9px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		/* color: #00cc88; */

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}

		.el-step__icon-inner {
			color: #999;
		}
	}
	/deep/ .el-input__validateIcon{
		display: none;
	}
	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-finish {
		color: #00cc88;
	}

	/* 步骤条结束 */
</style>
